import { Injectable, signal } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiBaseService } from '../api-base.service';
import {
  ICompany,
  ICompanyResponse,
  IGenerateApiKeyResponse,
  IPartialCompanyResponse,
} from './company.models';
import { environment } from '@/environments/environment';
import { IHttpResponseList, IHttpResponseObject } from '@/models/shared.models';
import { BaseHttpResponseModel } from '@/api/common/models/base-http-response.model';
import { CompanySearchModel } from '@/api/company/models/company-search.model';
import { PaginationV2Model } from '@/api/common/models/pagination-v2.model';
import { makeQueryParamsHelper } from '@/api/common/helper/make-query-params.helper';
@Injectable({
  providedIn: 'root',
})
export class CompanyService extends ApiBaseService {
  currentCompany$ = signal<ICompany>(null);

  private ENDPOINT = `${environment.api}/api/Companies`;
  private ENDPOINT_V2 = `${environment.api}/v2/api/Companies`;

  get(companyId: number) {
    return this.http
      .get<ICompanyResponse>(`${this.ENDPOINT}/${companyId}`)
      .pipe(this.extractResult());
  }

  getPage(page: number, limit: number): Observable<any> {
    return this.http.get(`${this.ENDPOINT}`);
  }

  getAllPartial() {
    return this.http
      .get<IPartialCompanyResponse>(`${this.ENDPOINT_V2}`)
      .pipe(this.extractResult());
  }

  addOne(company: ICompany) {
    return this.http.post<IHttpResponseObject<string>>(
      `${this.ENDPOINT}/`,
      company,
    );
  }

  updateOne(company: ICompany) {
    return this.http.put<IHttpResponseObject<string>>(
      `${this.ENDPOINT}/`,
      company,
    );
  }

  searchCompanies(
    pagination: PaginationV2Model,
  ): Observable<BaseHttpResponseModel<CompanySearchModel[]>> {
    return this.http.get<BaseHttpResponseModel<CompanySearchModel[]>>(
      this.ENDPOINT_V2 + '/' + 'SearchAllByName',
      { params: makeQueryParamsHelper(pagination) },
    );
  }

  generateApiKey(companyId: number) {
    return this.http
      .post<IGenerateApiKeyResponse>(`${this.ENDPOINT}/GenerateApiKey`, {
        companyId,
      })
      .pipe(this.extractResult());
  }

  delete(companyId: number) {
    return this.http.delete(`${this.ENDPOINT}`, {
      params: { companyId },
    });
  }
}
